export default class PortalServidorService {
  constructor(http) {
    this._http = http
  }

  async getDadosPessoaisByCpf(cpf) {
    const { data } = await this._http.get(
      `/api/portal-servidor/dados-pessoais/${cpf}`,
    )
    return data
  }

  async getHistoricoFuncionalByMatricula(matricula) {
    const { data } = await this._http.get(
      '/api/portal-servidor/historicoFuncional',
      {
        params: { matricula: matricula },
      },
    )
    return data
  }

  async getDadosFuncionaisByCpf(cpf) {
    const { data } = await this._http.get(
      `/api/portal-servidor/dados-funcionais/${cpf}`,
    )
    return data
  }

  async getDependentesByCpf(cpf) {
    const { data } = await this._http.get(
      `/api/portal-servidor/dependentes/${cpf}`,
    )
    return data
  }

  async getCodigoDeEtica() {
    const { data } = await this._http.get(
      `/api/portal-servidor/codigo-de-etica`,
      { responseType: 'blob' },
    )
    return data
  }
}
