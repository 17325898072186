<template>
  <div class="grid">
    <div class="col-11 div-center">
      <Card>
        <template #title> Código de Ética </template>
        <template #content>
          <div class="field col-12 md:col-12">
            <object
              :data="pdfBlobUrl"
              type="application/pdf"
              width="100%"
              height="600px" />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import PortalServidorService from '@/service/PortalServidorService.js'

export default {
  data() {
    return {
      pdfBlobUrl: null,
      pdfBlob: null,
    }
  },

  created() {
    this.portalServidorService = new PortalServidorService(this.$http)
    this.fetchExternalPDF()
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.pdfBlobUrl)
  },

  methods: {
    downloadPdf() {
      saveAs(this.pdfBlob, 'FICHA_FINANCEIRA.pdf')
    },
    fetchExternalPDF() {
      this.portalServidorService
        .getCodigoDeEtica()
        .then((res) => {
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          this.pdfBlob = blob
          this.pdfBlobUrl = URL.createObjectURL(blob)
        })
        .catch((error) => {
          console.error('Erro ao buscar o PDF externo:', error)
        })
    },
  },
}
</script>
<style>
.div-center {
  margin: auto;
}
</style>
